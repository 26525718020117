document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/g, "") + "js"

if (window.location.hash) { setTimeout(function () { window.scrollTo(0, 0); }, 2); }

jQuery(document).ready(function ($) {

    /*-----------------------------------------------------------------------------GLOBAL ON LOAD----*/

    //Load images, and images after doc ready
    var lazyLoadImages = (function () {
        var $lazyImages = $('img[data-src], iframe[data-src], [data-background]');
        if (!$lazyImages.length) { return; }

        function init() {
            $lazyImages.each(function () {
                var $this = $(this),
                    delay = 0,
                    dataSrc;
                if (dataSrc = $this.data('background')) {
                    if ($this.data('background-delay')) {
                        delay = $this.data('background-delay');
                    }
                    setTimeout(function () {
                        $this.css('background-image', 'url(' + dataSrc + ')');
                        $this.attr('data-background', null);
                    }, delay);
                } else if (dataSrc = $this.data('src')) {
                    if ($this.data('src-delay')) {
                        delay = $this.data('src-delay');
                    }
                    setTimeout(function () {
                        $this.attr('src', dataSrc);
                        $this.attr('data-src', null);
                    }, delay);
                }
            });
        }
        init();
        return { init: init }
    }());

   //Global function to smooth scroll inside pages
    var SmoothScroll = (function () {
        var $anchorLinks = $('a[href^="#"]').not('[href="#"]');

        function goTo(target) {
            if (target === "" || !$(target).length) { return; }
            var scrollPos = typeof target === 'number' ? target : $(target).offset().top;

            if (window.innerWidth >= 720) {
                scrollPos -= $('.gheader').outerHeight();
            } else {
                scrollPos -= $('.gheader').outerHeight() * 2;
            }

            $('html, body').stop().animate({
                'scrollTop': scrollPos
            }, 1200, 'swing', function () {
                if (typeof target === 'string') {

                    if (window.location.hash) {
                        window.location.hash = target;
                    }
                }
            });
        }

        if (window.location.hash) {
            setTimeout(function () {
                goTo(window.location.hash);
            }, 500);
        }

        if ($anchorLinks.length) {
            $anchorLinks.on('click', function (e) {
                if (!$("#" + this.hash.replace('#', '')).length) { return; }
                e.preventDefault();
                goTo(this.hash);
            });
        }

        return { to: goTo }
    }());


    //Global function to toggle simple accordions
    var Accordions = (function () {
        var $accordions = $('.accordion');
        if (!$accordions.length) { return; }

        if($accordions.hasClass('accordion--new') && $(window).width() > 960) {

            console.log("> 960");

        var $this = $(".accordions-block");
        $this.find(".accordion__trigger").first().addClass("active");
        var content = $this.find(".accordion__content").first().html();
        $this.find(".accordions-block-content").html(content);

        $(document).on('click', '.accordion__trigger', function () {
            var $this = $(this);
            $this.addClass('active');
            $this.parent().siblings().find('.accordion__trigger').removeClass('active');
            $('.accordions-block-content').empty();
            var content = $(this).closest('.accordion__item').find('.accordion__content').html();
            $(this).closest('.accordions-block').find('.accordions-block-content').html(content);
        });

        } else {

            console.log("< 960");
       
        $accordions.each(function () {
            $this = $(this).find('.accordion__trigger');
            if ($this.hasClass('active')) {
                $this.next().slideToggle(250);
            }
        });
        $(document).on('click', '.accordion__trigger', function () {
            var $this = $(this);
            $this.toggleClass('active');
            $this.next().slideToggle(250);
            $this.parent().siblings().find('.accordion__trigger').removeClass('active').next().slideUp(250);
        });
      }

    }());

    var Forms = (function () {
        var InputMasks = (function () {
            var $masks = $('[data-mask]');
            if ( !$masks.length ) { return; }

            /**
             * Key Codes:
             * 8    - backspace
             * 13   - enter
             * 16   - shift
             * 18   - alt
             * 20   - caps
             * 27   - esc
             * 37   - left arrow
             * 38   - up arrow
             * 39   - right arrow
             * 40   - down arrow
             * 46   - delete
             **/
            var exclude_keys = [ 8, 13, 16, 18, 20, 27, 37, 38, 39, 40, 46 ];

            $( '[data-mask]' ).keyup( function ( e ) {
                console.log( e.keyCode );
                if ( exclude_keys.indexOf( e.keyCode ) > -1 ) { return; }

                switch ( this.dataset.mask ) {
                    case 'digits':
                        var x = this.value.replace( /\D/g, '' );
                        this.value = x;
                        break;
                    case 'phone':
                        var x = this.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        console.log(x);
                        this.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                        break;
                    case 'ssn': {
                        var x = this.value.replace(/\D/g, '').match(/^(\d{0,3})(\d{0,2})(\d{0,4})/);
                        this.value = !x[2] ? x[1] : x[1] + '-' + x[2] + '-' + x[3];
                    }
                }
            });
        }());

        //Plugin used for form validation
        var parselyOptions = {
            classHandler: function (parsleyField) {
                var $element = parsleyField.$element;
                if ($element.parent().hasClass('select-menu')) {
                    return $element.parent();
                }
                return $element;
            },
            errorsContainer: function (parsleyField) {
                var $element = parsleyField.$element;
                var $fieldContainer = $element.closest('.form-field');
                if ($fieldContainer) {
                    return $fieldContainer;
                }
            }
        };

        //Global function to set form state classes
        var formStates = (function () {
            $formInputs = $('main form :input');
            if (!$formInputs.length) { return; }

            $formSelectMenus = $('.select-menu select, .ginput_container_select select');

            function isGFormInput($el) {
                return $el.hasClass('ginput_container') ? $el.parent() : $el;
            }

            function setFilled($input) {
                var $parent = isGFormInput($input.parent());

                $parent.addClass('filled');
            }

            function removeFilled($input) {
                var $parent = isGFormInput($input.parent());

                $parent.removeClass('filled');
            }

            function setFocused() {
                var $parent = isGFormInput($(this).parent());

                $parent.addClass('focused');
            }

            function removeFocused() {
                var $parent = isGFormInput($(this).parent());

                $parent.removeClass('focused');
            }

            function checkInput(e) {
                if (this.type == 'button' ||
                    this.type == 'range' ||
                    this.type == 'submit' ||
                    this.type == 'reset') { return; }

                var $this = $(this);
                var $parent = $this.parent();
                var is_selectMenu = $parent.hasClass('select-menu') || $parent.hasClass('ginput_container_select');

                var $input = is_selectMenu ? $parent : $this;

                switch (this.type) {
                    case 'select-one':
                    case 'select-multiple':
                        if (this.value !== '') {
                            setFilled($input);
                        } else {
                            removeFilled($input);
                        }
                        break;
                    default:
                        if (this.value) {
                            setFilled($input);
                        } else {
                            removeFilled($input);
                        }
                        break;
                }
            }

            $formInputs.each(checkInput);
            $formInputs.on('change', checkInput);
            $formInputs.on('focus', setFocused);
            $formInputs.on('blur', removeFocused);
            $formSelectMenus.on('focus', setFocused);
            $formSelectMenus.on('blur', removeFocused);

        }());
    }());

    //Global function top open / close lightboxes
    var PDMLightbox = (function () {
        //Lightbox reset - This lightbox is empty and present on all pages
        var $lightbox = $('.pdm-lightbox--reset');

        //it's content can be filled from various sources
        //after close, the content is wiped
        var $lightbox_content = $('.pdm-lightbox--reset .pdm-lightbox__content');

        $('body').on('click', '[data-lightbox-iframe],[data-lightbox-content],[data-lightbox-target],.lightbox-trigger', function (e) {

            e.preventDefault();

			var classes = $(this).data('lightbox-classes');
            var iframe = $(this).data('lightbox-iframe');

            if (iframe) {
                
                var youtubeMatch = iframe.match(/watch\?v=([a-zA-Z0-9\-_]+)/);
                
                if( youtubeMatch ){
                    iframe = '<iframe width="420" height="345" frameborder="0" allowfullscreen src="https://www.youtube.com/embed/' + youtubeMatch[1] +'" />';
                    classes += ' youtube-vid'

                } else {
                    iframe = '<video width="100%" height="100%" controls><source src="'+iframe+'" type="video/mp4"></video>'
                    classes += ' regular-vid'

                }

                $lightbox_content.html('<div class="iframe-wrap">' + iframe + '</div>');
				

            } else {
                if ($(this).data('lightbox-content')) {
                    var content = $(this).data('lightbox-content');
                } else if ($(this).data('lightbox-target')) {
                    var target = $(this).data('lightbox-target');
                    var content = $('#' + target).html();
                } else {
                    var content = $(this).closest('.lightbox-container').find('.lightbox-content').html();
                }
                $lightbox_content.html(content);
            }

            $lightbox.addClass('active').addClass(classes);

        });

        function closeLightbox($lightbox) {
            $lightbox.removeClass('active');
            $('body').removeClass('noScroll');
            //wait before removing classes till lightbox closses
            if ($lightbox.hasClass('pdm-lightbox--reset')) {
                setTimeout(function () {
                    $lightbox.find('.pdm-lightbox__content').empty();
                    $lightbox.attr('class', 'pdm-lightbox pdm-lightbox--reset');
                }, 750);
            }
        }

        $('.pdm-lightbox').on('click', function (e) {
            var $lightbox = $(this);
            if (e.target == this) {
                closeLightbox($lightbox);
            }
        });

        $('.pdm-lightbox__close').click(function (e) {
            e.stopPropagation();
            $lightbox = $(this).closest('.pdm-lightbox');
            closeLightbox($lightbox);
        });
        return {
            close: closeLightbox
        };
    }());

    var singlePost = (function () {

        if ($('body').is('.single-post, .single-case-studies, .page-template-default')) {

            $headings = $('.page-content h2');

            if ($headings.length) {

                $('.toc').show();

                $headings.each(function () {
                    var text = $(this).text().replace(':', '');
                    //                console.log(text);
                    var id = text.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-').toLowerCase();
                    $(this).attr('id', id);

                    $('.toc ul').append('<li><button class="reset" data-target="' + id + '">' + text + '</button>');
                });

                $('.toc').on('click', 'button', function () {
                    //                $('.toc li.active').removeClass('active');
                    //                $(this).parent().addClass('active');
                    var target = $(this).data('target');
                    $('html, body').animate({
                        scrollTop: $('h2#' + target).offset().top - 100
                    }, 1000);
                });

            }

            var $progressBar = $('.progress-bar .progress')
            var lastScrollTop = 0;

            function updateProgress() {
                var scrollableHeight = $('.page-content__row').outerHeight();
                var currentProgress = $('.page-content__row').position().top + $('.page-content__row').outerHeight();
                currentProgress = currentProgress;
                currentProgress = currentProgress - ($(window).scrollTop() + ($(window).height() / 2));
                currentProgress = 100 - ((currentProgress / scrollableHeight) * 100);
                $progressBar.css('width', currentProgress + '%');     
                if ($('.toc__container').length) {
                    var scrollTop = $(window).scrollTop();
                    $headings.each(function () {
                        var elementOffset = $(this).offset().top,
                            distance = (elementOffset - scrollTop);

                        if (scrollTop > lastScrollTop) {
                            if (distance > 0 && distance < 250) {
                                var tocItem = $(this).attr('id');
                                $('.toc li.active').removeClass('active');
                                $('.toc button[data-target="' + tocItem + '"]').parent().addClass('active');
                            }
                        } else {
                            if (distance < (250) && distance < (500)) {
                                var tocItem = $(this).closest('h2').attr('id');
                                $('.toc li.active').removeClass('active');
                                $('.toc button[data-target="' + tocItem + '"]').parent().addClass('active');
                            }
                        }
                    });

                    lastScrollTop = scrollTop;

                }                
            }
            $(window).scroll(updateProgress);
            $(window).resize(updateProgress);

        }

    }());    


    //******************************************************Everything under this is optional, feel free to delete

    var LoadMore = (function () {
        var $loadmore = $('#loadmore');
        if (!$loadmore.length) { return; }

        var $loadmore_text = $loadmore.text();

        $loadmore.click(function () {
            var $this = $(this);
            var $postlist = $this.siblings('.post-list');

            var query = $this.attr('data-query');
            var page = parseInt($this.attr('data-page'));
            var max = parseInt($this.attr('data-max'));

            var data = {
                action: 'load_more_posts',
                query: query,
                page: page
            };

            $.ajax({
                url: ajaxURL,
                type: 'post',
                data: data,
                beforeSend: function () {
                    $loadmore.attr('disabled', true).text('Loading Posts');
                },
                error: function (res) {
                    res = JSON.parse(res);
                    console.log(res);
                },
                success: function (res) {
                    res = JSON.parse(res);
                    var current_page = res.page;
                    var posts = res.posts;

                    if (res.status !== 'error') {
                        $loadmore.attr('disabled', false).text($loadmore_text);
                        $this.attr('data-page', current_page);
                        $postlist.append(posts);
                        lazyLoadImages.init();

                    } else {
                        $loadmore.remove();
                    }

                    if (current_page >= max) {
                        $loadmore.remove();
                    }
                }
            });
        });
    }());

    var footerMenus = (function () {
     $('.menu--foot').on('click', '.menu-item-has-children', function() {
         $('.dropdown-menu').removeClass("active");
            if ($(this).hasClass('active')) {
                $(this).removeClass("active");
                $(this).find('a').closest('.dropdown-menu').removeClass("active");
            } else {
                $('footer').find('.footer-menu-content').empty();
                $('footer').find('.menu-item-has-children').removeClass("active");
                var content = $(this).find('a').closest('.dropdown-menu').html();
                $(this).addClass("active");
                $(this).find('a').closest('.dropdown-menu').addClass("active");
                $('footer').find('.footer-menu-content').html(content);
            }
          });

    }());

	var centeredContent = (function(){
		
		$centered = $('.centered-content');
		
		if( !$centered.length ){ return; }
		
		var distance = $centered.offset().top,
		$window = $(window);

		$window.scroll(function() {
			if ( $window.scrollTop() >= distance ) {
				$centered.addClass('show-cards');
			}else{
				$centered.removeClass('show-cards');
			}
		});

	}());


var expandMore = (function() {
    $(document).on('click', '.expand', function () {
        $(this).toggleClass("active");
        $(".hidden-timeline").toggleClass("active");
    });
}());

var twoColumnSlider = (function() {
    $sliders = $('.image-slider'); 
    if( !$sliders.length ){ return };
    $sliders.each(function () {   
        var $slider = $(this);
        $slider.flickity({
            prevNextButtons: false,
            contain: false,
            pageDots: false,
            cellAlign: 'left',
            freeScroll: true,
        });

        $('.image-nav').click(function () {
            if ($(this).hasClass('next')) {
                $slider.flickity('next');
            } else {
                $slider.flickity('previous');
            }

        });

    }); 
}());

    var caseStudyBlock = (function() {
        
        $sliders = $('.case-studies-block .blog-posts');
        
        if( !$sliders.length ){ return };
        
        $sliders.each(function () {
            
            var $slider = $(this);
            $slider.flickity({
                prevNextButtons: false,
                contain: true,
                pageDots: true,
                cellAlign: 'left',
                watchCSS: true,
            });

        });
        
    }());

var testimonialSlider = (function() {
    $sliders = $('.testimonial-slider'); 
    if( !$sliders.length ){ return };
    $sliders.each(function () {   
        var $slider = $(this);
        $slider.flickity({
            prevNextButtons: false,
            contain: true,
            pageDots: false,
            cellAlign: 'left',
            freeScroll: true,
            wrapAround: true

        });

        $('.testimonial-nav').click(function () {
            if ($(this).hasClass('next')) {
                $slider.flickity('next');
            } else {
                $slider.flickity('previous');
            }

        });

    }); 
}());

var tradeshowSlider = (function() {
    $sliders = $('.tradeshow-slider'); 
    if( !$sliders.length ){ return };
    $sliders.each(function () {   
        var $slider = $(this);
        $slider.flickity({
            prevNextButtons: false,
            pageDots: false,
//            contain: true,
//            cellAlign: 'left',
			groupCells: true,
//            freeScroll: false,
        });
		
		$slider.on( 'ready.flickity', function() {
		  $slider.flickity('resize');
		});

        $('.tradeshow-nav').click(function () {
            if ($(this).hasClass('next')) {
                $slider.flickity('next');
            } else {
                $slider.flickity('previous');
            }

        });

    }); 
}());
	
	
	
	var testimonialContentSlider = (function(){
		
		$sliders = $('.testimonials-content__slider');
		
		if( !$sliders.length ){ return };
		
		$sliders.each(function () {
			
			var $slider = $(this);
			
			$slider.flickity({
				prevNextButtons: false,
				contain: true,
				pageDots: false,
				cellAlign: 'left',
				freeScroll: false,
				wrapAround: true,
				autoPlay: 7500,
			});

			$('.testimonials-content__nav button').click(function () {
				if ($(this).hasClass('flick-nav-arrow--next')) {
					$slider.flickity('next');
				} else {
					$slider.flickity('previous');
				}
			});

		}); 
		
	}());
	
	
	var testimonialContentSlider = (function(){
		
		$sliders = $('.image-sliders__main');
		
		if( !$sliders.length ){ return };
		
		$sliders.each(function () {
			
			var $slider = $(this);
			
			$slider.flickity({
				prevNextButtons: true,
				pageDots: false,
			
				draggable: false,
				asNavFor: $('.image-sliders__nav')[0]
			});

		}); 
		
		$sliders = $('.image-sliders__nav');
		
		if( !$sliders.length ){ return };
		
		$sliders.each(function () {
			
			var $slider = $(this);
			
			$slider.flickity({
				prevNextButtons: false,
				contain: true,
				pageDots: false,		
				asNavFor: $('.image-sliders__main')[0]
			});

		}); 
		
	}());
	
	
	var header = (function(){

        var $body = $('body');
        var $header = $('header.gheader');
        var $nav = $header.find('nav.global');

        var BurgerMenu = (function () {
            var $burgerMenu = $header.find('.menu-burger');
            var $text = $burgerMenu.find('.menu-burger__text');

            function activate() {
                $burgerMenu.addClass('active').attr('title', 'Close');
                $text.text('Close');
                $nav.addClass('active');
                $body.addClass('no-scroll');
            }

            function reset() {
                $burgerMenu.removeClass('active').attr('title', 'Menu');
                $text.text('Menu');
                $nav.removeClass('active').find('.active').removeClass('active');
                $body.removeClass('no-scroll');
            }

            $burgerMenu.click(function () {
                var $this = $(this);

                if ($this.hasClass('active')) { reset(); } else { activate(); }
            });
        }());

	   var NextSection = (function () {
			var $btn = $('#next-section');
			var $nextSection = $body.find('main > section');

			if (!$nextSection.length) { $btn.hide(); return; }

			$btn.click(function () {
				SmoothScroll.to($nextSection.offset().top);
			});
		}());

	 	$nav.on('click', function (e) {
            if (e.target == this) {
				$header.find('.menu-burger').trigger('click');
            }
        });

		
		var $dropdowns = $(".gheader .dropdown");
		var $dropdownLinks = $(".gheader .dropdown > a");
		
		$dropdownLinks.click(function(e){
			
			e.preventDefault();

			var $dropdown = $(this).parent();
		
			var opened = false;
			
			if( $dropdown.hasClass('opened') ){
				opened = true;
			}

			$dropdowns.removeClass('opened');
			$dropdowns.find('ul').removeClass('show');
			$dropdowns.find('ul').slideUp();
			
			if( opened == false ){
				$dropdown.addClass('opened');
				$dropdown.find('ul').first().slideDown();
				$dropdown.find('ul').first().addClass('show');
			}
			
		});
		
	}());
	

if (navigator.userAgent.indexOf('Mac OS X') != -1) {
  $(".btn").addClass("mac-font");
}

});

